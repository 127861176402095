<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Optometrist") }}</label>
      <span>/</span>
      <router-link to="/admin/optometrists">{{
        $t("Optometrist")
      }}</router-link>

      <span>/</span>
      <label v-if="id" active>{{ $t("Edit") }}</label>
      <label v-else active>{{ $t("Add") }}</label>
    </div>

    <div class="container-fluid">
      <div class="row">
        <h3 v-if="id" class="mt-10 mx-3">Edit Optometrist</h3>
        <h3 v-else class="mt-10 mx-3">Create New Optometrist</h3>
      </div>

      <div v-loading="loaderData"></div>

      <div v-if="!loaderData">
        <div class="row mt-5">
         
          <div class="col-md-3">
             <label for=""
              >{{ $t("User") }}
            </label>
            <v-autocomplete
              :clearable="false"
              class="d-block"
              name="admin_id"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="name"
              item-value="id"
              v-model="formData.admin_id"
              :items="adminList"
            ></v-autocomplete>
          </div>

           <div class="col-md-3">
            <label for=""
              >{{ $t("Name") }}
              <span @click="openPatientDialog" class="addBtn">+</span>
            </label>
            <v-autocomplete
              :clearable="false"
              class="d-block"
              name="name"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="patient_name"
              item-value="patient_id"
              v-model="formData.patient_id"
              :items="patientList"
              @input="changePatient(formData.patient_id)"
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <label for="">{{ $t("Registration date") }}</label>
            <el-date-picker
              style="width: 100%"
              :clearable="false"
              class="d-block"
              name="reg_date"
              v-model="formData.reg_date"
            ></el-date-picker>
          </div>
        </div>

        <div v-loading="loader"></div>
        <div
          class="container my-5"
          style="border: 1px solid #ccc"
          v-if="patientDetails !== null"
        >
          <div class="row">
            <div class="col-md-3">
              <label for="">Contact No.</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.mobile }}
              </p>
            </div>
            <div class="col-md-3">
              <label for="">Gender</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.gender == 0 ? $t("Male") : $t("Female") }}
              </p>
            </div>
            <div class="col-md-3">
              <label for="">Birth date</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.birth_date }}
              </p>
            </div>
            <div class="col-md-3">
              <label for="">Age</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.age }}
              </p>
            </div>
            <div class="col-md-3">
              <label for="">Optometrist reports count</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.count_optometrist_reports }}
              </p>
            </div>
            <div class="col-md-3">
              <label for="">Last date optometrist report</label>
              <p style="color: blue; margin: 10px 0">
                {{ patientDetails.last_date_optometrist_report }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="">Chief complaints</label>
            <!-- <VueEditor class="mb-2" v-model="formData.chief_complaints" /> -->
            <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.chief_complaints"
            />
          </div>
          <div class="col-md-12">
            <label for="">PAST MEDICAL HISTORY</label>
            <!-- <VueEditor class="mb-2" v-model="formData.past_medical_history" /> -->

             <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.past_medical_history"
            />

          </div>
          <div class="col-md-12">
            <label for="">PAST OCULAR HISTORY</label>

             <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.past_ocular_history"
            />
<!-- 
            <VueEditor class="mb-2" v-model="formData.past_ocular_history" /> -->
          </div>
        </div>

        <div class="row">
          <table id="table1" class="mt-5">
            <tr>
              <th>VISUAL ACUITY</th>
              <th>EYE</th>
              <th>
                UNAIDED
                <span @click="openConstantDialog(2)" class="addBtn">+</span>
              </th>
              <th>
                AIDED (Glasses)
                <span @click="openConstantDialog(3)" class="addBtn">+</span>
              </th>
              <th>
                AIDED (Contact lenses)
                <span @click="openConstantDialog(3)" class="addBtn">+</span>
              </th>
              <th>
                PH <span @click="openConstantDialog(7)" class="addBtn">+</span>
              </th>
              <th></th>
              <th>
                UNAIDED
                <span @click="openConstantDialog(2)" class="addBtn">+</span>
              </th>
              <th>
                AIDED
                <span @click="openConstantDialog(3)" class="addBtn">+</span>
              </th>
            </tr>
            <tr>
              <td rowspan="2">DISTANCE</td>
              <td>RE</td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.distance_right_unaided"
                  :items="unaidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="
                    formData.visual_acuities.distance_right_aided_glasses
                  "
                  :items="aidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="
                    formData.visual_acuities.distance_right_aided_contact_lenses
                  "
                  :items="aidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.distance_right_ph"
                  :items="phList"
                ></v-autocomplete>
              </td>
              <td rowspan="2">Near</td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.near_right_unaided"
                  :items="unaidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.near_right_aided"
                  :items="aidedList"
                ></v-autocomplete>
              </td>
            </tr>
            <tr>
              <td>LE</td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.distance_left_unaided"
                  :items="unaidedList"
                ></v-autocomplete>
              </td>

              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.distance_left_aided_glasses"
                  :items="aidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="
                    formData.visual_acuities.distance_left_aided_contact_lenses
                  "
                  :items="aidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.distance_left_ph"
                  :items="phList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.near_left_unaided"
                  :items="unaidedList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.visual_acuities.near_left_aided"
                  :items="aidedList"
                ></v-autocomplete>
              </td>
            </tr>
          </table>

        <div class="row mx-1 mt-1">
           <div class="col-md-12">
            <label for="">VISUAL ACUITY Notes:</label>
            <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.visual_acuities.note"
            />
           </div>

          </div>


          <table id="table2" class="mt-5">
            <tr>
              <th colspan="11">
                Present Glass Power | Contact Lenses Parameter
              </th>
            </tr>
            <tr>
              <th></th>
              <th colspan="5">RE</th>
              <th colspan="5">LE</th>
            </tr>
            <tr>
              <td>Contact Lenses Parameter</td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.contact_lenses_parameter.right_read"
                  hide-details
                ></v-text-field>
              </td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.contact_lenses_parameter.left_read"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td rowspan="2">Present Glass Power</td>
              <td>
                SPH <span @click="openConstantDialog(4)" class="addBtn">+</span>
              </td>
              <td>
                CYL
                <span @click="openConstantDialog(5)" class="addBtn">+</span>
              </td>
              <td>
                AXIS
                <span @click="openConstantDialog(6)" class="addBtn">+</span>
              </td>
              <td>
                ADD
                <span @click="openConstantDialog(8)" class="addBtn">+</span>
              </td>
              <td>VA</td>
              <td>
                SPH <span @click="openConstantDialog(4)" class="addBtn">+</span>
              </td>
              <td>
                CYL
                <span @click="openConstantDialog(5)" class="addBtn">+</span>
              </td>
              <td>
                AXIS
                <span @click="openConstantDialog(6)" class="addBtn">+</span>
              </td>
              <td>
                ADD
                <span @click="openConstantDialog(8)" class="addBtn">+</span>
              </td>
              <td>VA</td>
            </tr>
            <tr>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.right_sph"
                  :items="sphList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.right_cyl"
                  :items="cylList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.right_axis"
                  :items="axisList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.right_add"
                  :items="addList"
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  type="text"
                  v-model="formData.present_glass_power.right_va"
                  hide-details
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.left_sph"
                  :items="sphList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.left_cyl"
                  :items="cylList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.left_axis"
                  :items="axisList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.present_glass_power.left_add"
                  :items="addList"
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  type="text"
                  v-model="formData.present_glass_power.left_va"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
          </table>

            <div class="row mx-1 mt-1">
           <div class="col-md-12">
            <label for="">Present Glass Power Notes:</label>
            <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.present_glass_power.note"
            />
           </div>

          </div>

          <table id="table3" class="mt-5">
            <tr>
              <th></th>
              <th colspan="5">RE</th>
              <th colspan="5">LE</th>
            </tr>
            <tr>
              <td>Retinoscopy</td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.retinoscopy.right_read"
                  hide-details
                ></v-text-field>
              </td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.retinoscopy.left_read"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td>Autorefractometry value</td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.autorefractometry_value.right_read"
                  hide-details
                ></v-text-field>
              </td>
              <td colspan="5">
                <v-text-field
                  dense
                  class="mb-2"
                  outlined
                  type="text"
                  v-model="formData.autorefractometry_value.left_read"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td rowspan="2">Final Prescription</td>
              <td>
                SPH
                <span @click="openConstantDialog(4)" class="addBtn">+</span>
              </td>
              <td>
                CYL
                <span @click="openConstantDialog(5)" class="addBtn">+</span>
              </td>
              <td>
                AXIS
                <span @click="openConstantDialog(6)" class="addBtn">+</span>
              </td>
              <td>
                ADD
                <span @click="openConstantDialog(8)" class="addBtn">+</span>
              </td>
              <td>VA</td>
              <td>
                SPH
                <span @click="openConstantDialog(4)" class="addBtn">+</span>
              </td>
              <td>
                CYL
                <span @click="openConstantDialog(5)" class="addBtn">+</span>
              </td>
              <td>
                AXIS
                <span @click="openConstantDialog(6)" class="addBtn">+</span>
              </td>
              <td>
                ADD
                <span @click="openConstantDialog(8)" class="addBtn">+</span>
              </td>
              <td>VA</td>
            </tr>
            <tr>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.right_sph"
                  :items="sphList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.right_cyl"
                  :items="cylList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.right_axis"
                  :items="axisList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.right_add"
                  :items="addList"
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  type="text"
                  v-model="formData.final_prescription.right_va"
                  hide-details
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.left_sph"
                  :items="sphList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.left_cyl"
                  :items="cylList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.left_axis"
                  :items="axisList"
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  :clearable="false"
                  class="d-block"
                  hide-details
                  dense
                  outlined
                  :multiple="false"
                  item-text="name"
                  item-value="id"
                  v-model="formData.final_prescription.left_add"
                  :items="addList"
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  type="text"
                  v-model="formData.final_prescription.left_va"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
          </table>

          <div class="row mx-1 mt-1">
           <div class="col-md-12">
            <label for="">Final Prescription Notes:</label>
            <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.final_prescription.note"
            />
           </div>

          </div>

        </div>

       

        

        <div class="row">
          <div class="col-md-12">
            <label for="">OTHER DIAGNOSTIC TESTS</label>
            <!-- <VueEditor class="mb-2" v-model="formData.other_diagnostic_tests" /> -->

              <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.other_diagnostic_tests"
            />

          </div>
          <div class="col-md-12">
            <label for=""
              >DIAGNOSIS Right
              <span @click="openConstantDialog(1)" class="addBtn">+</span>
            </label>

            <v-autocomplete
              :clearable="true"
              class="d-block"
              hide-details
              dense
              outlined
              :multiple="true"
              item-text="name"
              item-value="id"
              v-model="formData.diagnosis"
              :items="diagnosisList"
            ></v-autocomplete>
          </div>

           <div class="col-md-12">
            <label for=""
              >DIAGNOSIS Left
              <span @click="openConstantDialog(1)" class="addBtn">+</span>
            </label>

            <v-autocomplete
              :clearable="true"
              class="d-block"
              hide-details
              dense
              outlined
              :multiple="true"
              item-text="name"
              item-value="id"
              v-model="formData.left_diagnosis"
              :items="diagnosisList"
            ></v-autocomplete>
          </div>

          <div class="col-md-12">
            <label for="">DIAGNOSIS NOTE</label>
             <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.diagnosis_note"
            />
          </div>

          <div class="col-md-12">
            <label for="">PLAN OF MANAGEMENT</label>

            <!-- <VueEditor class="mb-2" v-model="formData.plan_of_management" /> -->

             <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.plan_of_management"
            />
          </div>
          <div class="col-md-12">
            <label for="">REVIEW / NOTE</label>

            <!-- <VueEditor class="mb-2" v-model="formData.review_note" /> -->

            <v-textarea
              rows="5"
              dense
              class="mb-2"
              outlined
              type="text"
              hide-details
              v-model="formData.review_note"
            />

          </div>
        </div>

        <div class="row">
          <div style="display: block; margin-left: auto; margin-right: 10px">
            <el-button
              style="width: 140px"
              :type="id ? 'danger' : 'primary'"
              @click="saveData"
            >
              <span v-if="id">Update</span>
              <span v-else>Save</span>
            </el-button>
          </div>
        </div>
      </div>

      <v-dialog
        v-model="constantDialog"
        width="40rem"
        @click:outside="
          constantDialog = false;
          $validator.reset();
        "
      >
        <v-card>
          <v-card-text>
            <v-container>
              <h2 class="my-3" style="color: blue">New {{ ConstantTitle }}</h2>
              <v-row>
                <v-col class="py-2" :cols="8">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.name`),
                    }"
                  >
                    <p class="pt-3">{{ $t("Name") }}</p>
                    <v-text-field
                      class="d-block my-4"
                      type="text"
                      name="name"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Name')"
                      hide-details
                      dense
                      outlined
                      v-model="constantForm.name.en"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.name`)"
                  >
                    {{ errors.first(`addEditValidation.name`) }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="mx-5 mt-13"
                    color="success"
                    dark
                    outlined
                    small
                    style="width: 80%"
                    @click="saveConstant"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="patientDialog"
        width="50rem"
        @click:outside="
          patientDialog = false;
          $validator.reset();
        "
      >
        <v-card>
          <v-card-text>
            <v-container>
              <h2 class="my-3" style="color: blue">New Patient</h2>
              <v-row class="mt-5">
                <v-col class="py-0" :cols="4">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.name`),
                    }"
                  >
                    <label>{{ $t("Name") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="name"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Name')"
                      hide-details
                      dense
                      outlined
                      v-model="patientForm.name"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.name`)"
                  >
                    {{ errors.first(`addEditValidation.name`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="4">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.mobile`),
                    }"
                  >
                    <label>{{ $t("ContactNo") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="number"
                      name="mobile"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('ContactNo')"
                      hide-details
                      dense
                      outlined
                      v-model="patientForm.mobile"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.mobile`)"
                  >
                    {{ errors.first(`addEditValidation.mobile`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="4">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.gender`),
                    }"
                  >
                    <label>{{ $t("Gender") }}</label>
                    <v-autocomplete
                      :clearable="false"
                      class="d-block my-2"
                      name="gender"
                      hide-details
                      dense
                      outlined
                      :multiple="false"
                      item-text="name"
                      item-value="id"
                      v-model="patientForm.gender"
                      :items="genderItems"
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.gender`)"
                  >
                    {{ errors.first(`addEditValidation.gender`) }}
                  </div>
                </v-col>
                <v-col class="py-0" :cols="4">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.gender`),
                    }"
                  >
                    <label>{{ $t("BirthDate") }}</label>
                    <el-date-picker
                      :clearable="false"
                      class="d-block my-2"
                      name="birth_date"
                      v-model="patientForm.birth_date"
                      placeholder="1993-09-20"
                      @input="getAge"
                    ></el-date-picker>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.birth_date`)"
                  >
                    {{ errors.first(`addEditValidation.birth_date`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="4">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.age`),
                    }"
                  >
                    <label>{{ $t("Age") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="number"
                      readonly
                      name="age"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Age')"
                      hide-details
                      dense
                      outlined
                      :value="patientForm.age"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.age`)"
                  >
                    {{ errors.first(`addEditValidation.age`) }}
                  </div>
                </v-col>

                <v-col class="py-0" cols="4">
                  <v-btn
                    class="mx-5 mt-8"
                    color="success"
                    dark
                    outlined
                    small
                    style="width: 80%; height: 38px"
                    @click="savePatient"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import { VueEditor, Quill } from "vue2-editor";

export default {
  // components: { VueEditor },
  data() {
    return {
      id: this.$route.params.id || null,
      loader: false,
      loaderData: false,
      patientDetails: null,
      constantDialog: false,
      patientDialog: false,
      customerData: null,
      diagnosisList: [],
      unaidedList: [],
      aidedList: [],
      sphList: [],
      cylList: [],
      axisList: [],
      phList: [],
      addList: [],
      patientList: [],
      constantForm: {
        name: {
          en: null,
        },
      },
      patientForm: {
        name: null,
        mobile: null,
        gender: null,
        birth_date: null,
        age: null,
      },
      genderItems: [
        {
          id: 0,
          name: this.$t("Male"),
        },
        {
          id: 1,
          name: this.$t("Female"),
        },
      ],
      ConstantTitle: "",
      formData: {
        patient_id: null,
        admin_id: null,
        reg_date: null,
        chief_complaints: null,
        past_medical_history: null,
        past_ocular_history: null,
        diagnosis: [],
        left_diagnosis: [],
        other_diagnostic_tests: null,
        plan_of_management: null,
        review_note: null,
        visual_acuities: {
          distance_right_unaided: null,
          distance_left_unaided: null,
          distance_right_aided_glasses: null,
          distance_left_aided_glasses: null,
          distance_right_aided_contact_lenses: null,
          distance_left_aided_contact_lenses: null,
          distance_right_ph: null,
          distance_left_ph: null,
          near_right_unaided: null,
          near_left_unaided: null,
          near_right_aided: null,
          near_left_aided: null,
          note: null,
        },
        contact_lenses_parameter: {
          right_read: null,
          left_read: null,
        },

        present_glass_power: {
          right_sph: null,
          right_cyl: null,
          right_axis: null,
          right_add: null,
          right_va: null,
          left_sph: null,
          left_cyl: null,
          left_axis: null,
          left_add: null,
          left_va: null,
          note: null,
        },
        retinoscopy: {
          right_read: null,
          left_read: null,
        },

        autorefractometry_value: {
          right_read: null,
          left_read: null,
        },

        final_prescription: {
          right_sph: null,
          right_cyl: null,
          right_axis: null,
          right_add: null,
          right_va: null,
          left_sph: null,
          left_cyl: null,
          left_axis: null,
          left_add: null,
          left_va: null,
          note: null,
        },
      },
      adminList: [],
    };
  },
  methods: {
    getAdminList() {
      this.$store.dispatch(`admin/listData`).then((res) => {
        this.adminList = res.data;
      });
    },
    getAge() {
      this.patientForm.age = moment().diff(
        this.patientForm.birth_date,
        "years"
      );
    },
    openPatientDialog() {
      this.patientForm = {
        name: null,
        mobile: null,
        gender: null,
        birth_date: null,
        age: null,
      };
      this.$validator.reset();
      this.patientDialog = true;
    },
    openConstantDialog(id) {
      this.constantForm = {
        name: {
          en: "",
        },
      };

      this.$validator.reset();

      this.constant_id = id;
      this.constantDialog = true;

      if (id == 1) this.ConstantTitle = "DIAGNOSIS";
      else if (id == 2) this.ConstantTitle = "UNAIDED VISION";
      else if (id == 3) this.ConstantTitle = "AIDED VISION";
      else if (id == 4) this.ConstantTitle = "SPH";
      else if (id == 5) this.ConstantTitle = "CYL";
      else if (id == 6) this.ConstantTitle = "AXIS";
      else if (id == 7) this.ConstantTitle = "PH";
      else if (id == 8) this.ConstantTitle = "ADD";
    },
    savePatient() {
      let sendData = JSON.parse(JSON.stringify(this.patientForm));

      if (this.patientForm.birth_date)
        sendData.birth_date = moment(this.patientForm.birth_date).format(
          "YYYY-MM-DD"
        );

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.$store.dispatch(`patient/saveData`, sendData).then((res) => {
          this.patientDialog = false;
          this.notifySuccess("Save", res);
          this.loadPatientList();
        });
      });
    },
    saveConstant() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "Empty Fields");

        let sendData = {
          constant_id: this.constant_id,
          sendData: this.constantForm,
        };
        this.$store
          .dispatch(`constantDetail/saveData`, sendData)
          .then((res) => {
            this.constantDialog = false;
            this.notifySuccess("Save", res);
            this.loadConstantList(this.constant_id);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    loadLists() {
      this.loadConstantLists();
      // this.loadPatientList();
      // this.loadDiagnosisList();
      // this.loadUnaidedList();
      // this.loadAidedList();
      // this.loadSphList();
      // this.loadCylList();
      // this.loadAxisList();
      // this.loadPhList();
      // this.loadAddList();
    },
    loadConstantLists() {
      this.$store
        .dispatch(`optometristReport/getAllListsConstantDetails`)
        .then((res) => {
          this.diagnosisList = res.data.item_diagnosis;
          this.unaidedList = res.data.item_unaided_vision;
          this.aidedList = res.data.item_aided_vision;
          this.sphList = res.data.item_sph;
          this.cylList = res.data.item_cyl;
          this.axisList = res.data.item_axis;
          this.phList = res.data.item_ph;
          this.addList = res.data.item_add;
          this.patientList = res.data.item_patients;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadPatientList() {
      this.$store.dispatch(`patient/searchData`).then((res) => {
        this.patientList = res.data;
      });
    },

    changePatient(id) {
      if (id) {
        this.loader = true;
        this.$store
          .dispatch(`patient/findData`, id)
          .then((res) => {
            this.patientDetails = res.data;
          })
          .catch((error) => {
            this.notifyCustomError("Error", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    loadConstantList(id) {
      if (id == 1) this.loadDiagnosisList();
      if (id == 2) this.loadUnaidedList();
      if (id == 3) this.loadAidedList();
      if (id == 4) this.loadSphList();
      if (id == 5) this.loadCylList();
      if (id == 6) this.loadAxisList();
      if (id == 7) this.loadPhList();
      if (id == 8) this.loadAddList();
    },
    loadDiagnosisList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 1)
        .then((res) => {
          this.diagnosisList = res.data;
          console.log("tt");
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadUnaidedList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 2)
        .then((res) => {
          this.unaidedList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadAidedList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 3)
        .then((res) => {
          this.aidedList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadSphList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 4)
        .then((res) => {
          this.sphList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadCylList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 5)
        .then((res) => {
          this.cylList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadAxisList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 6)
        .then((res) => {
          this.axisList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadPhList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 7)
        .then((res) => {
          this.phList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    loadAddList() {
      this.$store
        .dispatch(`optometristReport/getListConstantDetails`, 8)
        .then((res) => {
          this.addList = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        });
    },
    saveData() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "Empty Fields");

        let sendData = JSON.parse(JSON.stringify(this.formData));

        if (this.formData.reg_date)
          sendData.reg_date = moment(this.formData.reg_date).format(
            "YYYY-MM-DD"
          );

        if (this.id) {
          (sendData.id = this.id),
            this.$store
              .dispatch(`optometristReport/updateData`, sendData)
              .then((res) => {
                this.notifySuccess("Update", res);
                this.$router.push({ name: "optometrists" });
              })
              .catch((error) => {
                this.notifyCustomError("Error", error);
              });
        } else {
          this.$store
            .dispatch(`optometristReport/saveData`, sendData)
            .then((res) => {
              this.notifySuccess("Save", res);
              this.$router.push({ name: "optometrists" });
            })
            .catch((error) => {
              this.notifyCustomError("Error", error);
            });
        }
      });
    },
  },
  created() {
    this.loadLists();
    this.getAdminList();

    this.formData.reg_date = moment(new Date());
    if (this.id) {
      this.loaderData = true;
      this.$store
        .dispatch(`optometristReport/findData`, this.id)
        .then((res) => {
          this.formData = res.data;
          this.changePatient(this.formData.patient_id);
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        })
        .finally(() => {
          this.loaderData = false;
        });
    }
  },
};
</script>

<style>
#table1,
#table2,
#table3 {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0 1%;
}

#table1 td,
#table2 td,
#table3 td,
#table1 th,
#table2 th,
#table3 th {
  border: 1px solid #000 !important;
  text-align: center;
  font-size: 13px;
  padding: 8px;
}

#table1 th,
#table2 th,
#table3 th {
  /* background-color: rgb(255, 253, 152) !important; */
  background-color: #fff !important;
  border: 1px solid #000 !important;
}

.addBtn {
  color: brown;
  cursor: pointer;
}
</style>